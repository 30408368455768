<template>
  <div id="dashboard">
    <div class="q-px-md">
      <div class="row justify-around">
        <div class="row q-col-gutter-x-md">
          <div class="col-3" v-for="(data, index) in summariesData" :key="index">
            <SummaryCard :selectedVision="'general'" :data="data" :key="index" :period2="period2" />
          </div>
        </div>
      </div>

      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col">
          <SummaryTable
            :data="combinedCampaignData"
            :title="'Full por Campanha'"
            :columns="commissionTableColumns"
            :type="'campaign'"
          />
        </div>
        <div class="col">
          <SummaryTable
            :data="combinedChannelData"
            :title="'Full por Canal'"
            :columns="commissionTableColumns"
            :type="'channel'"
          />
        </div>
      </div>
      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col-4">
          <SummaryBarChart :data="commissionPerMonthData" />
        </div>
        <div class="col-4">
          <SummaryPizzaChart
            :title="'Status das Conversões'"
            :datePeriod1="period"
            :datePeriod2="period2"
            :id="'commission-full-by-status'"
            :data="commissionPerStautsData"
            :data2="commissionPerStautsData2"
            :period2="!!period2"
          />
        </div>
        <div class="col-4">
          <SummaryLineChart
            :data="commissionPerDay"
            :title="'Comissão Full Por Dia'"
            :dataProperty="'comission_full'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryBarChart from '@/components/smartDashboard/SummaryBarChart.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import SmartDashboardMixin from '../../../mixins/SmartDashboardMixin';

export default {
  name: 'GeneralVision',
  components: {
    SummaryCard,
    SummaryTable,
    SummaryBarChart,
    SummaryPizzaChart,
    SummaryLineChart
  },
  mixins: [DashboardResumeService, SmartDashboardMixin],

  props: {
    filters: {
      require: true,
      type: [Object]
    },
    period: {
      require: true,
      type: [String]
    },
    period2: {
      require: false,
      type: [String]
    },
    ignoreTestCampaigns: {
      require: true,
      type: [Boolean, String]
    }
  },

  data() {
    return {
      commissionTableColumns: [
        {
          name: 'name',
          label: 'Nome',
          field: 'name',
          align: 'center',
          classes: 'text-blue text-bold ellipsis',
          style: 'max-width: 200px'
        },
        { name: 'commission_full', label: 'Valor', field: 'comission_full', align: 'center' },
        { name: 'percentage', label: '%', field: 'percentage', align: 'center' }
      ],
      commissionPerCampaign: [],
      commissionPerCampaign2: [],
      commissionPerChannel: [],
      commissionPerChannel2: [],
      commissionPerMonthData: [],
      commissionPerMonthData2: [],
      commissionPerStautsData: [],
      commissionPerStautsData2: [],
      commissionPerDay: [],
      commissionPerDay2: [],
      summariesData: {
        comission_full: {
          title: 'Comissão Full',
          value: '',
          value2: '',
          color: '#17ccf3',
          color2: '#e91e63',
          icon: 'mdi-trending-up',
          type: 'money'
        },
        cost: { title: 'Custo', value: '', value2: '', color: '#f8aa4c', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', value2: '', color: '#FBCB42', icon: 'mdi-chart-line', type: 'money' },
        margin_percent: {
          title: '% Margem',
          value: '',
          value2: '',
          color: '#766BFB',
          icon: 'mdi-percent-outline',
          type: 'percent'
        }
      }
    };
  },

  watch: {
    period2() {
      if (!this.period2) {
        this.commissionPerCampaign2 = [];
        this.commissionPerChannel2 = [];
        Object.keys(this.summariesData).forEach((key) => {
          this.summariesData[key].value2 = '';
        });
      }
    }
  },

  computed: {
    combinedCampaignData() {
      const dataPeriod1 = Array.isArray(this.commissionPerCampaign) ? this.commissionPerCampaign : [];
      const dataPeriod2 = Array.isArray(this.commissionPerCampaign2) ? this.commissionPerCampaign2 : [];

      const groupedData = {};

      dataPeriod1.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.name].values.unshift({ ...item, period: 1, color: 'blue' });
        groupedData[item.name].total = item.comission_full || 0;
      });

      dataPeriod2.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.name].values.push({ ...item, period: 2, color: 'pink' });

        if (groupedData[item.name].values.length > 1) {
          groupedData[item.name].hasBothPeriods = true;
        }
      });

      const combinedData = Object.values(groupedData)
        .sort((a, b) => {
          if (b.hasBothPeriods !== a.hasBothPeriods) {
            return b.hasBothPeriods - a.hasBothPeriods;
          }
          return b.total - a.total;
        })
        .flatMap((group) => group.values);

      return combinedData;
    },

    combinedChannelData() {
      const dataPeriod1 = Array.isArray(this.commissionPerChannel) ? this.commissionPerChannel : [];
      const dataPeriod2 = Array.isArray(this.commissionPerChannel2) ? this.commissionPerChannel2 : [];

      const groupedData = {};

      dataPeriod1.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.name].values.unshift({ ...item, period: 1, color: 'blue' });
        groupedData[item.name].total = item.comission_full || 0;
      });

      dataPeriod2.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.name].values.push({ ...item, period: 2, color: 'pink' });

        if (groupedData[item.name].values.length > 1) {
          groupedData[item.name].hasBothPeriods = true;
        }
      });

      const combinedData = Object.values(groupedData)
        .sort((a, b) => {
          if (b.hasBothPeriods !== a.hasBothPeriods) {
            return b.hasBothPeriods - a.hasBothPeriods;
          }
          return b.total - a.total;
        })
        .flatMap((group) => group.values);

      return combinedData;
    }
  },

  methods: {
    generateReport() {
      const config = {
        margin: [40, 40, 40, 40],
        filename: 'dashboard_general.pdf',
        marginTitle: 35
      };
      this.exportReport(config);
    },

    async getGeneralDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        var filterChartsString = '%3F';
        filterString += this.periodString();
        Object.values(this.filters).forEach((filter) => {
          if (filter.value !== '' && filter.value !== null) {
            filterString += filter.name + '%3D' + filter.value + '%26';
            filterChartsString += filter.name + '%3D' + filter.value + '%26';
          }
        });
        filterString += 'ignore_test%3D' + this.ignoreTestCampaigns;
        filterChartsString += 'ignore_test%3D' + this.ignoreTestCampaigns;

        const [summaryData, campaignData, channelData, monthData, statusData, dailyData] = await Promise.all([
          this.getSmartDashboardResume(filterString),
          this.getCampaignCommissionFull(filterString),
          this.getChannelCommissionFull(filterString),
          this.getCommissionPerMonth(filterChartsString),
          this.getCommissionByStatus(filterString),
          this.getCommissionFullDaily(filterChartsString)
        ]);

        Object.keys(summaryData.data).forEach((key) => {
          if (this.summariesData[key]) {
            this.summariesData[key].value = summaryData.data[key];
          }
        });

        this.commissionPerCampaign = campaignData.data;
        this.commissionPerChannel = channelData.data;
        this.commissionPerMonthData = monthData.data;
        this.commissionPerStautsData = statusData.data.sort((a, b) => a.status - b.status);
        this.commissionPerDay = dailyData.data;

        if (this.period2) {
          const filterStringPeriod2 = filterString + '%26' + this.period2String();
          const filterChartsStringPeriod2 = filterChartsString + '%26' + this.period2String();

          const [summaryData2, campaignData2, channelData2, monthData2, statusData2, dailyData2] = await Promise.all([
            this.getSmartDashboardResume(filterStringPeriod2),
            this.getCampaignCommissionFull(filterStringPeriod2),
            this.getChannelCommissionFull(filterStringPeriod2),
            this.getCommissionPerMonth(filterChartsStringPeriod2),
            this.getCommissionByStatus(filterStringPeriod2),
            this.getCommissionFullDaily(filterChartsStringPeriod2)
          ]);

          Object.keys(summaryData2.data).forEach((key) => {
            if (this.summariesData[key]) {
              this.summariesData[key].value2 = summaryData2.data[key];
            }
          });

          this.commissionPerCampaign2 = campaignData2.data;
          this.commissionPerChannel2 = channelData2.data;
          this.commissionPerMonthData2 = monthData2.data;
          this.commissionPerStautsData2 = statusData2.data.sort((a, b) => a.status - b.status);
          this.commissionPerDay2 = dailyData2.data;
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  mounted() {
    this.getGeneralDashboardResume();
  }
};
</script>

<style></style>
