var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{attrs:{"padding":""}},[_c('q-toolbar',{staticClass:"q-px-md q-mb-md"},[_c('div',{staticClass:"q-mr-auto"},[_c('q-toolbar-title',{staticClass:"text-weight-medium text-h5"},[_vm._v("Dashboard ")]),_c('q-breadcrumbs',{},[_c('q-select',{staticClass:"text-caption",staticStyle:{"width":"180px"},attrs:{"map-options":"","options":[
            { label: 'Visão Geral', value: 'general' },
            { label: 'Detalhes da Campanha', value: 'campaign' },
            { label: 'Detalhes de Canais', value: 'channel' }
          ],"emit-value":"","dense":"","hide-bottom-space":"","borderless":""},model:{value:(_vm.selectedVision),callback:function ($$v) {_vm.selectedVision=$$v},expression:"selectedVision"}})],1)],1),_c('div',[_c('div',{staticClass:"row justify-end"},[_c('q-btn',{staticClass:"q-mb-sm",attrs:{"flat":"","dense":"","icon-right":"mdi-download-outline","color":"primary","label":"Baixar PDF","no-caps":""},on:{"click":_vm.generateReport}})],1),_c('div',{staticClass:"row"},[(_vm.selectedVision == 'general')?_c('q-checkbox',{staticClass:"q-mr-sm",attrs:{"label":"Ignorar campanhas de teste","true-value":"1","false-value":"0"},model:{value:(_vm.ignoreTestCampaigns),callback:function ($$v) {_vm.ignoreTestCampaigns=$$v},expression:"ignoreTestCampaigns"}}):_vm._e(),_c('date-input',{staticClass:"q-mr-sm",attrs:{"buttons":"","dense":"","outlined":"","label":"Período","v-model":_vm.period,"today-btn":"","range":"","compare":"","label-color":"primary"},on:{"update":(val) => {
              _vm.period = val;
            },"dateCompare":_vm.onDateCompare}}),(_vm.dateCompare)?_c('date-input',{staticClass:"q-mr-sm",attrs:{"buttons":"","clearable":"","dense":"","outlined":"","label":"Segundo Período","v-model":_vm.period2,"today-btn":"","range":"","label-color":"secondary","icon-color":"secondary"},on:{"update":(val) => {
              _vm.period2 = val;
            }}}):_vm._e(),(_vm.selectedVision == 'general')?_c('q-btn-dropdown',{staticClass:"q-mr-sm",attrs:{"no-caps":"","outline":"","content-style":"width: 380px","menu-offset":[0, 10],"label":"Filtros"}},[_c('div',{staticClass:"row"},_vm._l((Object.values(_vm.filters)),function(filter,index){return _c('div',{key:index,staticClass:"col-12 justify-between items-center row"},[_c('div',{staticClass:"items-center flex q-pa-md",attrs:{"rounded":""}},[_vm._v(_vm._s(filter.label))]),_c('q-btn',{staticStyle:{"font-size":"12px"},attrs:{"color":"white","text-color":"blue","unelevated":"","icon":filter.editing ? 'keyboard_arrow_left' : 'keyboard_arrow_down',"round":"","no-caps":""},on:{"click":function($event){filter.editing = !filter.editing}}}),_c('transition',{attrs:{"name":"fade"}},[(filter.editing)?_c('div',{staticClass:"col-12 q-pa-md",staticStyle:{"width":"full"}},[_c('q-select',{attrs:{"options":filter.name == 'statuses' ? filter.options : filter.optionsFiltered,"map-options":"","emit-value":"","label":filter.label,"option-label":filter.option_label,"option-value":filter.option_value,"outlined":"","clearable":"","use-input":""},on:{"filter":(val, update) => update(_vm.selectFilter(val, index, filter.option_label))},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1):_vm._e()])],1)}),0),_c('div',{staticClass:"flex justify-center q-pa-md q-mt-sm"},[_c('q-btn',{staticStyle:{"width":"160px"},attrs:{"label":"Remover Filtros","unelevated":"","color":"black"},on:{"click":_vm.resetFilters}})],1)]):_vm._e(),(_vm.selectedVision == 'channel')?_c('q-select',{staticClass:"q-mr-sm relative-position",staticStyle:{"width":"240px"},attrs:{"outlined":"","clearable":"","dense":"","use-input":"","map-options":"","label":_vm.filters.channel.label,"display-value":_vm.filters.channel.value == ''
              ? ''
              : _vm.filters.channel.value.name.length > 20
              ? _vm.filters.channel.value.name.slice(0, 14) + '...'
              : _vm.filters.channel.value.name,"input-class":"absolute","options":_vm.filters.channel.optionsFiltered,"option-label":_vm.filters.channel.option_label,"option-value":_vm.filters.channel.option_value},on:{"clear":(val) => (_vm.filters.channel.value = ''),"filter":(val, update) => {
              _vm.filters.channel.value = '';
              update(_vm.selectFilter(val, 4, _vm.filters.channel.option_label));
            }},model:{value:(_vm.filters.channel.value),callback:function ($$v) {_vm.$set(_vm.filters.channel, "value", $$v)},expression:"filters.channel.value"}}):_vm._e(),(_vm.selectedVision == 'campaign')?_c('q-select',{staticClass:"q-mr-sm relative-position",staticStyle:{"width":"240px"},attrs:{"outlined":"","clearable":"","dense":"","use-input":"","map-options":"","label":_vm.filters.campaign.label,"display-value":_vm.filters.campaign.value == ''
              ? ''
              : _vm.filters.campaign.value.name.length > 20
              ? _vm.filters.campaign.value.name.slice(0, 14) + '...'
              : _vm.filters.campaign.value.name,"input-class":"absolute","options":_vm.filters.campaign.optionsFiltered,"option-label":_vm.filters.campaign.option_label,"option-value":_vm.filters.campaign.option_value},on:{"clear":(val) => (_vm.filters.campaign.value = ''),"filter":(val, update) => {
              _vm.filters.campaign.value = '';
              update(_vm.selectFilter(val, 3, _vm.filters.campaign.option_label));
            }},model:{value:(_vm.filters.campaign.value),callback:function ($$v) {_vm.$set(_vm.filters.campaign, "value", $$v)},expression:"filters.campaign.value"}}):_vm._e(),(_vm.selectedVision !== 'general')?_c('q-select',{staticClass:"q-mr-md",staticStyle:{"width":"180px"},attrs:{"outlined":"","clearable":"","dense":"","map-options":"","emit-value":"","label":_vm.filters.status.label,"options":_vm.filters.status.options,"option-label":_vm.filters.status.option_label,"option-value":_vm.filters.status.option_value},model:{value:(_vm.filters.status.value),callback:function ($$v) {_vm.$set(_vm.filters.status, "value", $$v)},expression:"filters.status.value"}}):_vm._e(),_c('q-btn',{attrs:{"color":"primary","padding":"7px 7px","unelevated":"","icon":"search","dense":""},on:{"click":_vm.retrieveDashboardResume}})],1)])]),(_vm.selectedVision === 'general')?_c('GeneralVision',{ref:"generalVision",attrs:{"filters":_vm.filters,"period":_vm.period,"period2":_vm.period2,"ignoreTestCampaigns":_vm.ignoreTestCampaigns}}):_vm._e(),(_vm.selectedVision === 'campaign')?_c('CampaignVision',{ref:"campaignVision",attrs:{"filters":_vm.filters,"period":_vm.period,"period2":_vm.period2,"ignoreTestCampaigns":_vm.ignoreTestCampaigns}}):_vm._e(),(_vm.selectedVision === 'channel')?_c('ChannelVision',{ref:"channelVision",attrs:{"filters":_vm.filters,"period":_vm.period,"period2":_vm.period2,"ignoreTestCampaigns":_vm.ignoreTestCampaigns}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }