import { render, staticRenderFns } from "./SmartDashboard.vue?vue&type=template&id=3dea3657"
import script from "./SmartDashboard.vue?vue&type=script&lang=js"
export * from "./SmartDashboard.vue?vue&type=script&lang=js"
import style0 from "./SmartDashboard.vue?vue&type=style&index=0&id=3dea3657&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QToolbar,QToolbarTitle,QBreadcrumbs,QSelect,QBtn,QCheckbox,QBtnDropdown});
