<template>
  <q-card
      bordered
      flat
      :class="'row q-pa-sm  grow ' + (data.title == 'Conversões' ? ' offset-md-1' : '')"
      :style="`min-height: 100px; border-color: ${data.color}`"
  >
      <q-card-section :style="{ color: data.color }" class="col-12 row q-pa-sm text-bold justify-between">
      <p
          class="col-8 flex items-center q-mb-none align-center"
          :class="selectedVision == 'general' ? 'text-subtitle2' : 'text-caption'"
      >
          {{ data.title }}
      </p>
      <div class="">
          <q-icon :name="data.icon" :size="selectedVision == 'general' ? 'sm' : 'xs'" />
      </div>
      </q-card-section>

      <q-card-section :class="'text-weight-medium ' + (selectedVision == 'general' ? '' : 'q-pa-none  text-subtitle')">
      <money
          readonly
          class="my-input q-pa-none text-weight-bold text-primary"
          :class="selectedVision == 'general' ? 'text-h5' : 'text-caption'"
          v-model="data.value"
          v-bind="moneyTypes[data.type]"
          />
      <money
          v-if="period2"
          readonly
          class="my-input q-pa-none text-weight-bold text-secondary"
          :class="selectedVision == 'general' ? 'text-h5' : 'text-caption'"
          v-model="data.value2"
          v-bind="moneyTypes[data.type]"
          />
          <!-- :style="{ color: '#e91e63' }" -->
      </q-card-section>
  </q-card>
</template>


<script>
import { Money } from 'v-money';

export default {
  name: 'SummaryCard',
  components: { Money },
  props: {
    data: {
      require: true,
      type: [Object]
    },
    period2: {
      require: false,
      type: String
    },

    selectedVision: {
      require: true,
      type: [String]
    }
  },
  data() {
    return {
      moneyTypes: {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$  ',
          precision: 2,
          masked: false
        },

        percent: {
          decimal: ',',
          thousands: '.',
          prefix: ' ',
          suffix: '  %',
          precision: 2,
          masked: false
        },

        quantity: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          precision: 0
        }
      }
    };
  }
};
</script>

<style>
.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}

.my-input {
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 6px 10px;
  transition: border-color 0.3s;
  width: 95%;
}

.my-input:hover {
  cursor: default;
}
</style>
