export default {
  methods: {
    userProfile() {
      return this.$http.get(`/v2/auth`);
    },

    async userHistory() {
      return await this.$http.get(`/v2/user/history`);
    },

    async checkResetPass() {
      return await this.$http.get('/user/check/resetpass');
    },

    async getCnpjData(cnpj) {
      return await this.$http.get(`/cnpj/data/${cnpj}`);
    },

    async getCpfData(cnpj) {
      return await this.$http.get(`/cpf/data/${cnpj}`);
    },

    async getManagersOptions() {
      return await this.$http.get(`/user/managers`)
    },

    async getAffiliateManagerOptions() {
      return await this.$http.get(`/user/user-by-profile/8`);
    },
    
    async approveAffiliateAccountExclusion(affiliateId) {
      return await this.$http.delete(`/users/delete/affiliate/${affiliateId}`)
    }
  }
};
