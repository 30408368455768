import html2pdf from 'html2pdf.js';

export default {
  methods: {
    async exportReport(config) {
      const opt = {
        margin: config.margin,
        filename: config.filename,
        image: { type: 'jpeg', quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'b3', orientation: 'l' }
      };
      this.onLoading(true);
      try {
        this.headerPrint = true;
        const pdf = await html2pdf().from(document.getElementById('dashboard')).set(opt).toPdf().get('pdf');

        pdf.setFontSize(15);
        pdf.text(
          `Período: ${this.period} ${this.period2 ? '/ Segundo Período ' + this.period2 : ''}`,
          pdf.internal.pageSize.getWidth() / 2,
          config.marginTitle || 35,
          { align: 'center' }
        );

        await pdf.save(config.filename);
      } catch (e) {
        console.error(e);
      } finally {
        this.headerPrint = false;
        this.onLoading(false);
      }
    }
  }
};
