<template>
  <q-card flat class="q-pa-md" bordered style="border-color: #0086e6">
      <p class="text-body1 text-bold">{{ title }}</p>
      <div>
        <p v-if="period2" class="text-h6 text-primary">{{ datePeriod1 }}</p>
          <canvas :id="id"></canvas>
      </div>
      <div v-if="period2">
        <p class="text-h6 text-secondary">{{ datePeriod2 }}</p>
          <canvas :id="id + '-2'"></canvas>
      </div>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryPizzaChart',

  props: {
    data: { require: true, type: Array, default: () => [{ status_description: '', quantity: 0 }] },
    data2: { required: false, type: Array, default: () => [] },
    id: { require: true, type: String, default: 'pizza-chart' },
    title: { require: true, type: String, default: '' },
    period2: { required: false, default: false },
    datePeriod1: { required: true, type: String },
    datePeriod2: { required: false, type: String },
  },

  data() {
    return {
      chart: null,
      chart2: null,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      labelColors: {
        recusado: 'rgba(207, 73, 70, 1)',
        pendente: 'rgba(248, 237, 93, 1)',
        aprovado: 'rgba(125, 203, 91, 1)'
      },
      labelColorsPeriod2: {
        recusado: 'rgba(207, 73, 70, 1)',
        pendente: 'rgba(248, 237, 93, 1)',
        aprovado: 'rgba(125, 203, 91, 1)'
      }
    };
  },

  watch: {
    data: function () {
      this.mountChart();
    },
    data2: function () {
      if (this.period2) this.mountChart(true);
    }
  },
  methods: {
    mountChart(isSecond = false) {
      const labelColors = isSecond ? this.labelColorsPeriod2 : this.labelColors;
      const dataset = isSecond ? this.data2 : this.data;
      const ctx = document.getElementById(isSecond ? this.id + '-2' : this.id);
      if (!ctx) return;

      const labels = dataset.map((val) => val.status_description + ' | ' + val.quantity);
      const data = dataset.map((val) => val.percentage);

      if (isSecond && this.chart2) {
        this.chart2.destroy();
      } else if (!isSecond && this.chart) {
        this.chart.destroy();
      }

      const newChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Captações',
              data: data,
              backgroundColor: dataset.map((val) => labelColors[val.status_description.toLowerCase()]),
              borderColor: 'rgba(20, 50, 155, 1)',
              borderWidth: 0.5
            }
          ]
        },
        options: {
          tooltips: {
              callbacks: {
                // eslint-disable-next-line
                label: function (item, chart) {
                  var label = this._data.labels[item['index']].split(' | ');
                  return label[0] + ':  ' + chart['datasets'][0]['data'][item['index']] + ' % (' + label[1] + ')';
              }
            }
          },
          legend: {
            position: 'bottom'
          },
          scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return ' ';
                    }
                  }
                }
              ]
            }
          }
      });

      if (isSecond) {
        this.chart2 = newChart;
      } else {
        this.chart = newChart;
      }
    }
  },

  mounted() {
    this.mountChart();
    if (this.period2) this.mountChart(true);
  }
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  align-items: center;
}

canvas {
  max-width: 100%;
  max-height: 100%;
}

/* .date-period1 {
  color: #003366;
}

.date-period2 {
  color: #e91e63;
} */
</style>
