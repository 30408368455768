<template>
  <div id="dashboard">
    <div class="q-px-md">
      <div class="row no-wrap q-gutter-md">
        <SummaryCard
          v-for="(data, index) in campaignSummaries"
          :selectedVision="'campaign'"
          :data="data"
          :key="index + data.title"
          :period2="period2"
        />
      </div>

      <div class="row q-col-gutter-x-md q-mt-lg">
        <div class="col-6">
          <SummaryTable
            class="q-mb-lg"
            :data="period2 ? combinedCampaignChannels : campaignChannelResults"
            :columns="channelTableColumns"
          />

          <div class="row q-col-gutter-x-sm">
            <div class="col-6">
              <SummaryLineChart
                :id="'commission-full-daily-value-chart-campaign'"
                :title="'Conversões por dia'"
                :dataProperty="'transactions'"
                :data="campaignCommissionByDay"
              />
            </div>
            <div class="col-6">
              <SummaryPizzaChart
                :id="'commission-by-status-chart-campaign'"
                :data="campaignCommissionPerStatus"
                :data2="campaignCommissionPerStatus2"
                :period2="!!period2"
                :datePeriod1="period"
                :datePeriod2="period2"
                :title="'Aprovação'"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <SummaryDoubleBarChart :data="campaignTransactionsByMonth" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import SummaryDoubleBarChart from '@/components/smartDashboard/SummaryDoubleBarChart.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import SmartDashboardMixin from '../../../mixins/SmartDashboardMixin';

export default {
  name: 'CampaignVision',
  components: {
    SummaryCard,
    SummaryTable,
    SummaryPizzaChart,
    SummaryLineChart,
    SummaryDoubleBarChart
  },

  mixins: [DashboardResumeService, SmartDashboardMixin],
  props: {
    filters: {
      require: true,
      type: [Object]
    },
    period: {
      require: true,
      type: [String]
    },
    period2: {
      require: false,
      type: [String]
    },
    ignoreTestCampaigns: {
      require: true,
      type: [Boolean, String]
    }
  },
  data() {
    return {
      campaignCommissionByDay: [],
      campaignCommissionPerStatus: [],
      campaignCommissionPerStatus2: [],
      campaignTransactionsByMonth: [],
      campaignChannelResults: [],
      campaignChannelResults2: [],
      campaignSummaries: {
        comission_full: {
          title: 'Comissão Full',
          value: '',
          value2: '',
          color: '#747474',
          icon: 'mdi-trending-up',
          type: 'money'
        },
        cost: { title: 'Custo', value: '', value2: '', color: '#747474', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', value2: '', color: '#747474', icon: 'mdi-chart-line', type: 'money' },
        margin_percentage: {
          title: '% Margem',
          value: '',
          value2: '',
          color: '#747474',
          icon: 'mdi-percent-outline',
          type: 'percent'
        },
        transactions: { title: 'Conversões', value: '', value2: '', color: '#FE7FEB', type: 'quantity' },
        affiliates: { title: 'Afiliados', value: '', value2: '', color: '#FE7FEB', type: 'quantity' },
        average_ticket: { title: 'Ticket Médio', value: '', value2: '', color: '#FE7FEB' }
      },
      channelTableColumns: [
        {
          name: 'channel_name',
          label: 'Canal',
          field: 'channel_name',
          align: 'center',
          sortable: true
        },
        {
          name: 'transactions',
          label: 'Conversão',
          field: 'transactions',
          align: 'center',
          sortable: true
        },
        {
          name: 'average_ticket',
          label: 'Ticket Médio',
          field: (row) => parseFloat(row.average_ticket),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'comission_full',
          label: 'Comissão Full',
          field: (row) => parseFloat(row.comission_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'cost',
          label: 'Custo',
          field: (row) => parseFloat(row.cost),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'margin',
          label: 'Margem',
          field: (row) => parseFloat(row.margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'share_full',
          label: '% Share full',
          field: (row) => parseFloat(row.share_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => val + ' %'
        },

        {
          name: 'validation',
          label: 'Taxa de Validação',
          field: (row) => parseFloat(row.validation),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val, row) => parseFloat(val).toLocaleString('pt-br') + ' %'
        },
        {
          name: 'share_margin',
          label: '% Share margem',
          field: (row) => parseFloat(row.share_margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => val + ' %'
        }
      ]
    };
  },

  watch: {
    period2() {
      if (!this.period2) {
        this.campaignChannelResults2 = [];
        Object.keys(this.campaignSummaries).forEach((key) => {
          this.campaignSummaries[key].value2 = '';
        });
      }
    }
  },

  computed: {
    combinedCampaignChannels() {
      const dataPeriod1 = Array.isArray(this.campaignChannelResults) ? this.campaignChannelResults : [];
      const dataPeriod2 = Array.isArray(this.campaignChannelResults2) ? this.campaignChannelResults2 : [];

      const groupedData = {};

      dataPeriod1.forEach((item) => {
        if (!groupedData[item.channel_name]) {
          groupedData[item.channel_name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.channel_name].values.unshift({ ...item, period: 1, color: 'blue' });
        groupedData[item.channel_name].total = item.comission_full || 0;
      });

      dataPeriod2.forEach((item) => {
        if (!groupedData[item.channel_name]) {
          groupedData[item.channel_name] = { values: [], total: 0, hasBothPeriods: false };
        }
        groupedData[item.channel_name].values.push({ ...item, period: 2, color: 'pink' });

        if (groupedData[item.channel_name].values.length > 1) {
          groupedData[item.channel_name].hasBothPeriods = true;
        }
      });

      const combinedData = Object.values(groupedData)
        .sort((a, b) => {
          if (b.hasBothPeriods !== a.hasBothPeriods) {
            return b.hasBothPeriods - a.hasBothPeriods;
          }
          return b.total - a.total;
        })
        .flatMap((group) => group.values);

      return combinedData;
    }
  },

  methods: {
    generateReport() {
      const config = {
        margin: [80, 30, 40, 30],
        filename: 'dashboard_campaign.pdf',
        marginTitle: 50
      };
      this.exportReport(config);
    },

    async getCampaignDashboardResume() {
      this.campaignChannelResults2 = [];

      try {
        this.onLoading(true);

        if (this.campaignSummaries.comission_full.value2) this.resetCampaignVisionData();

        var filterString = '%3F';
        filterString += 'campaign_id' + '%3D' + this.filters.campaign.value.id;

        if (this.filters.status.value !== '' && this.filters.status.value !== null) {
          filterString += '%26status' + '%3D' + this.filters.status.value;
        }

        const [
          dashboardResume,
          campaignChannelTable,
          dailyCommisionData,
          campaignCommissionByStatus,
          campaignTransactionsByMonth
        ] = await Promise.all([
          await this.getCampaignVisionSmartDashboardResume(filterString + '%26' + this.periodString()),
          await this.getCampaignChannelResume(filterString + '%26' + this.periodString()),
          await this.getCampaignCommissionByDay(filterString),
          await this.getCampaignCommissionByStatus(filterString + '%26' + this.periodString()),
          await this.getCampaignTransactionsByMonth(filterString)
        ]);

        Object.keys(this.campaignSummaries).forEach((key) => {
          this.campaignSummaries[key].value = dashboardResume.data[key];
        });

        this.campaignChannelResults = campaignChannelTable.data;
        this.campaignCommissionByDay = dailyCommisionData.data;
        this.campaignCommissionPerStatus = campaignCommissionByStatus.data;
        this.campaignTransactionsByMonth = campaignTransactionsByMonth.data;

        if (this.period2) {
          const [dashboardResume2, campaignChannelTable2, campaignCommissionByStatus2] = await Promise.all([
            await this.getCampaignVisionSmartDashboardResume(filterString + '%26' + this.period2String()),
            await this.getCampaignChannelResume(filterString + '%26' + this.period2String()),
            await this.getCampaignCommissionByStatus(filterString + '%26' + this.period2String())
          ]);

          Object.keys(this.campaignSummaries).forEach((key) => {
            this.campaignSummaries[key].value2 = dashboardResume2.data[key];
          });

          this.campaignChannelResults2 = campaignChannelTable2.data;
          this.campaignCommissionPerStatus2 = campaignCommissionByStatus2.data;
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    resetCampaignVisionData() {
      Object.values(this.campaignSummaries).forEach((val) => {
        val.value = '';
        val.value2 = '';
      });
    }
  },

  mounted() {
    this.resetCampaignVisionData();
  }
};
</script>
